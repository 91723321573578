import useDebounceSearch from '../../../../hooks/debounceSearch';
import GisogdDocumentsProxy from '../../proxies/gisogdDocumentsProxy';

export default function useDocIzOrigin(documentTypeId) {
  const callback = (filter) =>
    GisogdDocumentsProxy.getDocumentsAllowedToChange(documentTypeId, filter);
  const { items, fetchWithDelay } = useDebounceSearch(callback);

  return {
    docs: items,
    fetchWithDelay,
  };
}
